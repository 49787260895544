var vueApp = new Vue({
    el: '#appCutters',
    data: {
        searchText: '',
        viewMode: 'view',
        selectedLargeImage: '',
        formdata: {
            name: '',
            id: 0,
            location_id: 0,
            categories: [],
            companies: [],
            tags: [],
            artwork: '',
            images: [],
            serial: '' //not used for edit, only view
        },
        tagInput: '',
        companyInput: [],
        cutters: [],
        categories: [],
        companies: [],
        tags: [],
        locations: [],
        errorMessages: []
    },
    ready: function() {
        var that = this;
        this.getCutters();
        this.getTags();
        this.getCompanies();

        // Categories won't change so they just need a one-off fetch now
        this.$http.get('/tag/list/category').then((response) => {
            this.categories = JSON.parse(response.body);
        }, (response) => {
           this.pushError('Unknown error loading categories.');
        });

        this.$http.get('/location/list').then((response) => {
            this.locations = JSON.parse(response.body);
        }, (response) => {
            this.pushError('Unknown error loading locations.');
        });
    },
    methods: {

        removeError : function(index) {
            this.errorMessages.splice(index,1);
        },

        pushError : function(e) {
            this.errorMessages.push(e);
            console.log(this.errorMessages);
        },

        setLargeImage: function(path) {
            this.selectedLargeImage = path;
        },

        setViewMode: function(mode) {
            this.viewMode = mode;
        },
        // Fetch a list of all cutters
        getCutters: function() {
            this.$http.get('/cutter/list').then((response) => {
                this.cutters = JSON.parse(response.body);
            }, (response) => {
                this.pushError('Unknown error loading cutters.');
            });
        },

        // Save a cutter
        saveCutter: function() {

            this.$http.post('/cutter', this.formdata).then((response) => {

                var doInitDropzone = this.formdata.id == 0;

                // Add this to our known list of cutters
                var returned_cutter = JSON.parse(response.body);
                var add_cutter = true;
                for (var i in this.cutters) {
                    if(this.cutters[i].id == returned_cutter.id) {
                        this.cutters.$set(i,returned_cutter);
                        add_cutter = false;
                        break;
                    }
                }

                if(add_cutter) {
                    this.cutters.push(returned_cutter);
                }

                if(doInitDropzone) {
                    this.openCutter(returned_cutter);
                }

                // Refresh the tag list (categories can not change)
                this.getCompanies();

            }, (response) => {
                if(response.status == 422) {
                    for(var ekey in response.data) {
                        this.pushError(response.data[ekey]);
                    }
                } else {
                    this.pushError('Unknown error adding cutter.');
                }
            });
        },

        deleteCutter: function() {
            this.$http.post('/cutter/'+this.formdata.id, {_method: 'DELETE'}).then((response) =>{
                var deleted_cutter = JSON.parse(response.body);
                for(var i in this.cutters) {
                    if(this.cutters[i].id == deleted_cutter) {
                        this.cutters.splice(i, 1);
                        this.prepareNewCutter();
                        break;
                    }
                }
            }, (response) => {
                this.pushError('Unknown error deleting cutter.');
            });
        },

        setSearchString: function(str) {
            this.searchText = str;
        },

        /**
         * Company list
         */

        getCompanies: function() {
            this.$http.get('/tag/list/company').then((response) => {
                this.companies = JSON.parse(response.body);
            }, (response) => {
               this.pushError('Unknown error loading companies.');
            });
        },

        deleteCompany: function(company) {
            var newCompanies = [];
            for (var i in this.formdata.companies) {
                if(this.formdata.companies[i]  != company) {
                    newCompanies.push(this.formdata.companies[i]);
                }
            }
            this.formdata.companies = newCompanies;
        },

        addCompany: function() {
            var company = this.companyInput.trim();
            if(company.length > 0) {
                this.companyInput = '';
                for(var i in this.formdata.companies) {
                    if(this.formdata.companies[i] == company) {
                        return;
                    }
                }

                this.formdata.companies.push(company);
            }
        },

        /**
         * Tag list and other tag stuff
         * Note that deleting and adding is referring to on-screen operations and they
         * will not affect DB data.
         */

        getTags: function() {
            this.$http.get('/tag/list/tag').then((response) => {
                this.tags = JSON.parse(response.body);
            }, (response) => {
                this.pushError('Unknown error loading tags.');
            });
        },

        deleteTag: function(tag) {
            var newTags = [];
            for (var i in this.formdata.tags) {
                if (this.formdata.tags[i] != tag) {
                    newTags.push(this.formdata.tags[i]);
                }
            }
            this.formdata.tags = newTags;
        },

        addTag: function() {
            var tag = this.tagInput.trim();
            if(tag.length > 0) {
                this.tagInput = '';
                for (var i in this.formdata.tags) {
                    if (this.formdata.tags[i] == tag) {
                        return;
                    }
                }
                this.formdata.tags.push(tag);
            }
        },

        openCutter: function(cutter) {
            this.formdata.name = cutter.name;
            this.formdata.id = cutter.id;
            this.formdata.location_id = cutter.location_id;
            this.formdata.companies = cutter.companies || [];
            this.formdata.tags = cutter.tags || [];
            this.formdata.categories = cutter.categories || [];
            this.formdata.artwork = cutter.artwork || '';
            this.formdata.images = cutter.images || [];
            this.formdata.serial = cutter.serial || '';

            this.selectedLargeImage = '';

            if(this.formdata.images.length > 0) {
                this.setLargeImage(this.formdata.images[0].path);
            }

            var that = this;

            setTimeout(function() {
                if(that.isEditMode) {
                    if(that.formdata.artwork == "") {
                        artworkDropzone == null;
                        artworkDropzone = new Dropzone('#artworkUpload');
                    }

                    imagesDropzone = null;
                    imagesDropzone = new Dropzone('#imagesUpload');
                }
            },1500);
        },

        prepareNewCutter: function() {
            this.formdata.name = '';
            this.formdata.id = 0;
            this.formdata.location_id = 0;
            this.formdata.categories =  [];
            this.formdata.companies = [];
            this.formdata.tags = [];
            this.formdata.artwork = '';
            this.formdata.images = [];

            this.companyInput = '';
            this.tagInput = '';

            artworkDropzone = null;
            imagesDropzone = null;

            this.viewMode = 'edit';
        },

        deleteArtwork: function() {
            this.formdata.artwork = '';

            this.$http.post('/cutter/' + this.formdata.id + '/artwork', {_method: 'DELETE'}).then((response) => {

                setTimeout(function() {
                    artworkDropzone == null;
                    artworkDropzone = new Dropzone('#artworkUpload');
                },1000);

            }, (response) => {
                this.pushError('Unknown error deleting artwork.');
            });
        },

        deleteImage: function(image) {

            this.$http.post('/cutter/' + this.formdata.id + '/image/' + image.id, {_method: 'DELETE'}).then((response) => {
                for (var i in this.formdata.images) {
                    if(this.formdata.images[i].id == image.id) {
                        this.formdata.images.splice(i, 1);
                    }
                }

                this.selectedLargeImage = '';

                if(this.formdata.images.length > 0) {
                    this.setLargeImage(this.formdata.images[0].path);
                }

            }, (response) => {
                this.pushError('Unknown error deleting image.');
            });

        },

        locationString: function(id) {
            var str = '';

            for(var i in this.locations) {
                if(this.locations[i].id == id) {
                    str = this.locations[i].name;
                    break;
                }
            }

            return str;
        },

        categoryString: function(id) {
            var str = '';

            for(var i in this.categories) {
                if(this.categories[i].id == id) {
                    str = this.categories[i].name;
                    break;
                }
            }

            return str;
        }

    },
    computed: {
        isViewMode: function() {
            return this.viewMode == 'view';
        },
        isEditMode: function() {
            return this.viewMode == 'edit';
        }
    },
    watch: {
        viewMode: function() {
            if(this.viewMode == 'edit') {
                // Autocomplete for company name
                new autoComplete({
                    selector: '#companyInput',
                    minChars: 2,
                    source: function(term, suggest) {
                        term = term.toLowerCase();
                        var matches = [];
                        for (i = 0; i < vueApp.companies.length; i++) {
                            if (~vueApp.companies[i].name.toLowerCase().indexOf(term)) {
                                matches.push(vueApp.companies[i].name);
                            }
                        }
                        suggest(matches);
                    }
                });

                // Autocomplete for tag
                new autoComplete({
                    selector: '#tagInput',
                    minChars: 2,
                    source: function(term, suggest) {
                        term = term.toLowerCase();
                        var matches = [];
                        for (i = 0; i < vueApp.tags.length; i++) {
                            if (~vueApp.tags[i].name.toLowerCase().indexOf(term)) {
                                matches.push(vueApp.tags[i].name);
                            }
                        }
                        suggest(matches);
                    }
                });

                var that = this;

                setTimeout(function() {
                    if(that.isEditMode) {
                        if(that.formdata.id > 0) {
                            if(that.formdata.artwork == "") {
                                artworkDropzone == null;
                                artworkDropzone = new Dropzone('#artworkUpload');
                            }

                            imagesDropzone = null;
                            imagesDropzone = new Dropzone('#imagesUpload');
                        }
                    }
                },1500);
            }
        }
    }
})


//initialise dropzone manually as need to bind to events.
Dropzone.autoDiscover = false;

Dropzone.options.artworkUpload = {
    init: function () {
        this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                this.removeAllFiles();
            }
        });

        this.on("success", function(file, response) {
            vueApp.formdata.artwork  = response.artwork;
            if(response.id > 0) {
                for (var i in vueApp.cutters) {
                    if(vueApp.cutters[i].id == response.id) {
                        vueApp.cutters[i].artwork = response.artwork;
                        break;
                    }
                }
            }
        });
    }
};

Dropzone.options.imagesUpload = {
    init: function () {
        this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                this.removeAllFiles();
            }
        });

        this.on("success", function(file, response) {
            if(typeof response.images !== 'undefined') {
                vueApp.formdata.images = response.images;
            } else {
                vueApp.pushError('Unknown error uploading image.');
            }

            if(response.id > 0) {
                for (var i in vueApp.cutters) {
                    if(vueApp.cutters[i].id == response.id) {
                        vueApp.cutters[i].images = response.images;
                        break;
                    }
                }
            }
        });
    }
};

var artworkDropzone = null;
var imagesDropzone = null;

Vue.filter('cutterFilter', function(values, arg) {
    arg = arg.toLowerCase();
    var arg_parts = arg.split(' ');
    var matches = [];

    for(i = 0; i < values.length; i++) {
        var do_add = false;
        
        if(!do_add) {
            if(~values[i].name.toLowerCase().indexOf(arg)) {
                do_add = true;
            }
        }

        if(!do_add) {
            for(var ci = 0; ci < values[i].companies.length; ci++) {
                if(~values[i].companies[ci].toLowerCase().indexOf(arg)) {
                    do_add = true;
                    break;
                }
            }
        }

        if(!do_add) {

            for(var ti = 0; ti < values[i].tags.length; ti++) {
                if(~values[i].tags[ti].toLowerCase().indexOf(arg)) {
                    do_add = true;
                    break;
                } else {
                    for(var ap = 0; ap < arg_parts.length; ap++) {
                        if(~values[i].tags[ti].toLowerCase().indexOf(arg_parts[ap])) {
                            do_add = true;
                            break;
                        }
                    }
                }
            }
        }

        if(!do_add) {
            for(var ci = 0; ci < values[i].categories.length; ci++) {
                if(~vueApp.categoryString(values[i].categories[ci]).toLowerCase().indexOf(arg)) {
                    do_add = true;
                    break;
                }
            }
        }

        if(do_add) {
            matches.push(values[i]);
        }
    }

    matches = matches.sort(function(a,b) {
        if(a.serial > b.serial) {
            return 1;
        } else if(a.serial < b.serial) {
            return -1;
        }
        return 0;
    });

    return matches;
});


